import React from 'react';
import {withStyles} from '@material-ui/core';
import styles from 'components/commonStyles';
import PropTypes from 'prop-types';
import {graphql, StaticQuery, Link} from 'gatsby';
import Img from 'gatsby-image';
import get from 'lodash/get';

const Case5 = ({classes}) => (
  <StaticQuery
    query={graphql`
      query {
        image: allFile(
          filter: { relativePath: { eq: "other/api-chooser/5.png" } }
        ) {
          edges {
            node {
              id
              childImageSharp {
                id
                fluid(
                  srcSetBreakpoints: [200, 340, 520, 800, 890]
                  quality: 100
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const img = get(data, 'image.edges[0].node.childImageSharp.fluid', {});

      return (
        <div className={classes.case} id="regular-pos">
          <div className={classes.head}>
            <h4 className={classes.h4Absolute}>Use Case #5. Regular POS</h4>
          </div>
          <p className={classes.textHead} style={{marginBottom: 60}}>
            You have a standalone app that has own database with sales and
            articles and you want to have a central storage for your data to
            backup and syncronize the data between POS installations or exchange
            with 3rd party systems.
          </p>
          <Img fluid={img} style={{maxWidth: 617}} />
          <p style={{marginTop: 60}}>
            This case assumes either POS gets Article from the server, but also
            POS can
            <span className={classes.textHead}> optionally </span>
            have own local changes (even made in offline!) and send those
            changes back to the server.
          </p>
          <p style={{marginTop: 20}}>
            For POS written in Java it’s a native API to establish
            synchronization between the app and the server.
          </p>
          <p style={{marginTop: 20}}>
            For other languages you will use JSON-REST API as described at
            Synchronization and Cashing article .
          </p>
          <p style={{marginTop: 20}}>
            Or another alternative to implement synchronization is to take a
            look at sources of SynchronizationSample and translate it from Java
            to your language. Article Master-master sync in Java will help you a
            lot. At the bottom of this article you will find a guide for
            migration to another language. For non-strong-typed languages you
            might write less amount of code than at java’s
            SynchronizationSample, so you can adopt it as you believe is more
            optimal.
          </p>
          <p style={{marginTop: 20}}>
            Also you might find{" "}
            <Link to="/developers/dot-net/">
              <span className={classes.textColor}>.NET</span>
            </Link>{" "}
            article kind of useful.
          </p>
          <p style={{marginTop: 20}}>
            In order to get the data, POS must be authorized by providing an{" "}
            <Link to="/developers/api-key/">
              <span className={classes.textColor}>API key</span>
            </Link>
            .
          </p>
        </div>
      );
    }}
  />
);

Case5.propTypes = {
  classes: PropTypes.shape({
    case: PropTypes.string.isRequired,
    head: PropTypes.string.isRequired,
    h4Absolute: PropTypes.string.isRequired,
    textHead: PropTypes.string.isRequired,
    textColor: PropTypes.string.isRequired,
  }).isRequired,
};

export default withStyles(styles, {withTheme: true})(Case5);
